import Mmenu from 'mmenu-js';

const MenuMobile = {
  init: function() {

    const mmenu = new Mmenu('#menu-mobile',
      {
        //options
        extensions: ['pagedim-black'],
        navbar: {
          add: true,
        },
      },
      {
        // configuration
        offCanvas: {
          pageSelector: '#wrapper',
        },
      }
    )

  
    $('[data-action="open-menu-mobile"]').on('click', function (event) {
      event.preventDefault();
  
      if ($('#menu-mobile').hasClass('mm-menu--opened')) {
        mmenu.close();
      } else {
        mmenu.open();
      }
    });
  }
};

export default MenuMobile
