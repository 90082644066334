import { setCartDrawer } from "../components/vndaComponents";

const HeaderCart = {
  button: document.querySelector('[data-toggle-cart]'),
  selector: '[data-cart-count]',
  attr: '[data-cart-count-attr]',
  text: '[data-cart-item-text]',

  getCartItens: async function() {
    try {
      const itens = await $.ajax({
        url: '/carrinho/itens',
        type: 'GET',
        dataType: 'text',
      })

      return itens;

    } catch (error) {
      console.error('Erro ao buscar a quantidade de produtos do carrinho');
      console.error(error);
    }
  },

  updateCartCount: async function(_itemsCount = null) {
    let items = _itemsCount;
    if (_itemsCount == null) {
      items = await this.getCartItens();
    }

    $(this.attr).data('cart-count-attr', items);
    $(this.attr).attr('data-cart-count-attr', items);

    $(this.attr).html(items);
    $(this.text).html(items);
  },
  
  show: function () {
    if (window.componentCartDrawer) return window.componentCartDrawer.open()
  },

  init: function() {
    setCartDrawer();

    this.updateCartCount();
  }
};

export default HeaderCart;
