import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination])

const Carrossel = {
  init: function () {
    const carrosselSections = document.querySelectorAll('[data-carrossel]');
    carrosselSections.forEach((section) => {
      const carousel = section.querySelector('.swiper');
      const next = section.querySelector('.swiper-button-next');
      const prev = section.querySelector('.swiper-button-prev');
      const pagination = section.querySelector('.pagination');
  
      const newCarrossel = new Swiper(carousel, {
        slidesPerView: 'auto',
        spaceBetween: parseFloat(section.dataset.carrosselSpacebetweenMobile),
        watchOverflow: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
          disabledClass: '-disabled',
        },
        pagination: {
          el: pagination,
          type: 'bullets',
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '">' +
              '<svg class="icon">' +
              '<use xlink:href="#icon-leaf"/>' +
              '</svg>' +
              '</span>'
            );
          },
        },
        breakpoints: {
          640: {
            slidesPerView: parseFloat(section.dataset.carrosselSlidesperview),
            spaceBetween: parseFloat(section.dataset.carrosselSpacebetween),
          },
        },
      });
    });
  },
};

export default Carrossel;
