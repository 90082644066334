const Popup = {
  closePopup: function (_popup) {
    $('[data-popup="' + _popup + '"]').removeClass('active');
  },
  togglePopup: function (_popup) {
    $('.active[data-popup]').removeClass('active');

    if (_popup != '') {
      if ($('.active[data-popup]').length > 0) {
        setTimeout(function () {
          $('[data-popup="' + _popup + '"]').addClass('active');
        }, 350);
      } else {
        $('[data-popup="' + _popup + '"]').addClass('active');
      }
    }
  },
  init: function () {
    var _this = this;
    $(document).on('click', '[data-action="open-popup"]', function (event) {
      event.preventDefault();
      //console.info('event open-opopup');
      var popup = $(this).data('popup');
      _this.togglePopup(popup);
    });

    $(document).on('click', '[data-action="close-popup"]', function (event) {
      event.preventDefault();
      //console.info('event close-opopup');
      var popup = $(this).data('popup');
      _this.closePopup(popup);
    });

    $('[data-trigger-popup="cart"]').on('click', function (event) {
      event.preventDefault();

      if (!$('[data-popup="cart"]').hasClass('active')) {
        $('[data-popup="cart"]').fadeOut();
        $('[data-popup="cart"]').removeClass('active');
      }

      if ($(this).parent().data('cart-count-attr') > 0) {
        $('[data-popup="cart"]').fadeToggle();
        $('[data-popup="cart"]').toggleClass('active');
      } else {
        window.location.href = '/carrinho';
      }
    });

    $(document).on('click', function (event) {
      if (!$(event.target).closest('[data-popup]').length) {
        var popup = $('body').find('.popup.active');

        popup.fadeOut(500, function () {
          popup.removeClass('active');
        });
      }
    });
  }
};

export default Popup;
