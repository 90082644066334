const Webforms = {
  submitForm: function(_$form) {
    _$form.find('[name="reply_to"]').val(_$form.find('[name="email"]').val());

    if (!_$form.hasClass('sending')) {
      $.ajax({
        url: '/webform',
        type: 'POST',
        data: _$form.serialize(),
        beforeSend: function () {
          _$form.addClass('sending');
        },
      })
        .done(function () {
          // console.info('foi');
          _$form.find('[data-msg-retorno] [data-msg-success]').addClass('visible');
          _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');
          _$form[0].reset();
          setTimeout(() => {
            _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
          }, 3500)
        })
        .fail(function () {
          // console.info('foi, mas deu ruim');
          _$form.find('[data-msg-retorno] [data-msg-success]').removeClass('visible');
          _$form.find('[data-msg-retorno] [data-msg-error]').addClass('visible');
          setTimeout(() => {
            _$form.find('[data-msg-retorno] [data-msg-error]').removeClass('visible');
          }, 3500)
        })
        .always(function () {
          console.log('complete');
          _$form.removeClass('sending');
        });
    }
  },

  init: function() {

    const _this = this;

    $('[data-webform]').on('submit', function (event) {
      event.preventDefault();
      var $form = $(this);
      var vnda = $(this).find('[name="vnda"]');
  
      if (vnda.length > 0) {
        if (vnda.val() != '') {
          //console.info('ROBOT DETECTED');
          return false;
        }
      }
  
      _this.submitForm($form);
    });
  }
}

export default Webforms;
