import HeaderCart from './common/headerCart';
import Carrossel from './components/carrossel';
import MenuMobile from './common/menuMobile';
import FixedHeader from './common/fixedHeader';
import Webforms from './common/webforms';
import Popup from './components/popup';

import { getPriceProd } from './components/utilities';
import { setNewsletterPopup } from './components/vndaComponents';

const page = document.querySelector('body').getAttribute('data-page');

// ==========================================
// Inicialização
// ==========================================
console.log('%cVnda - Tecnologia em Ecommerce', 'color: #f88d5b; font-size: 15px; font-family: "Verdana", sans-serif; font-weight: bold;');

window.addEventListener('DOMContentLoaded', () => {

  getPriceProd();
  HeaderCart.init();
  MenuMobile.init();
  Carrossel.init(); // precisa ser no main??
  FixedHeader.init();
  Webforms.init();
  Popup.init();

  if (page == 'home') {
    import('./pages/home')
      .then(({ default: Home }) => { Home.init() })
      .catch(error => { console.error(error) })
  }
  
  if (page == 'product') {
    import('./pages/product')
      .then(({ default: Product }) => { Product.init() })
      .catch(error => { console.error(error) })
  }
  
  if (page == 'login') {
    import('./pages/loginPage')
      .then(({ default: LoginPage }) => { LoginPage.init() })
      .catch(error => { console.error(error) })
  }

  if (page == 'page') {
    import('./pages/page')
      .then(({ default: Page }) => { Page.init() })
      .catch(error => { console.error(error) })
  }
  
  if (page == 'tag') {
    import('./pages/tag')
      .then(({ default: Tag }) => { Tag.init() })
      .catch(error => { console.error(error) })
  }
  
});
